import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser'
import { MappedImage } from '../Image'

export const Footer = ({
  footerNavOne,
  footerNavTwo,
  footerText,
  footerLogo,
  socialItems,
  copyrightText,
}) => (
  <footer
    className="footer py-4"
    style={{ backgroundColor: '#2067b7', color: '#fff' }}
  >
    <Container fluid className="py-4" style={{ maxWidth: '1200px' }}>
      <Row className="footer-inner mx-auto">
        <Col sm={12} md={3} className="my-3">
          <div className="h-100 w-100 d-flex flex-column justify-content-start">
            <div className="logo-wrapper mb-4">
              {footerLogo && (
                <MappedImage
                  style={{ maxHeight: '75px' }}
                  image={footerLogo}
                  alt={`${footerLogo?.title}`}
                />
              )}
            </div>
            <div className="footer-nav">
              <ul className="nav flex-column footer-nav-list">
                {footerNavOne &&
                  footerNavOne.map(item => {
                    return (
                      <li key={item.navigationTitle} className="nav-item">
                        <a
                          href={item.navigationLink}
                          className="nav-link text-white footer-top-nav"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.navigationTitle}
                        </a>
                      </li>
                    )
                  })}
              </ul>
              <div className="social-icons-outer d-flex flex-column">
                <ul className="social-icons-inner d-inline-flex justify-content-between m-0">
                  {socialItems &&
                    socialItems.map(item => {
                      return (
                        <li
                          key={item.navigationLink}
                          className="social-icons-item text-center"
                        >
                          <a
                            href={item.navigationLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.socialIcon && (
                              <MappedImage
                                style={{ maxWidth: '30px' }}
                                src={item.socialIcon?.file.url}
                                alt=""
                              />
                            )}
                          </a>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={9}>
          {footerText && parse(footerText.footerText)}
          {copyrightText && parse(copyrightText.copyrightText)}
        </Col>
      </Row>
      <Row></Row>
      <Row className="footer-bottom-nav my-4">
        <div className="footer-subnav">
          <ul className="nav justify-content-center">
            {footerNavTwo &&
              footerNavTwo.map(item => {
                return (
                  <li
                    key={item.navigationTitle}
                    className="nav-item subnav-item"
                  >
                    <a
                      className="nav-link footer-bottom-nav text-white"
                      href={item.navigationLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.navigationTitle}
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
      </Row>
    </Container>
  </footer>
)

export const mapFooterProps = props => {
  const {
    footerNavOne,
    footerNavTwo,
    footerText,
    footerLogo,
    socialItems,
    copyrightText,
  } = props
  return {
    footerNavOne,
    footerNavTwo,
    footerText,
    socialItems,
    copyrightText,
    footerLogo,
  }
}
